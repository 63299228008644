import React from "react";
import heroLanding from "src/assets/heroLanding.jpg";
import { useNavigate, useLocation } from "react-router-dom";
export default function Main() {
  const navigate = useNavigate();
  const location = useLocation();
  const handleBlogsNavigation = () => {
    if (location.pathname != "/") navigate("/");
    window.scrollTo({
      top: 630,
      behavior: "smooth",
    });
  };
  return (
    <div>
      <img src={heroLanding} alt="" className="lg:h-[90vh] sm:w-screen z-0" />
      <div className="z-50 text-white absolute md:top-56 sm:top-28 md:px-20 sm:px-7 flex flex-col gap-4">
        <div className="lg:text-8xl md:text-6xl sm:text-3xl font-bold italic">
          Culinary Guide
        </div>
        <div className="lg:text-4xl md:text-2xl sm:text-base font-serif font-semibold">
          Your Guide to taste!
        </div>
        <div className="flex gap-4">
          <button
            className="bg-themeColor md:rounded-lg sm:rounded-md md:px-4 md:py-3 md:text-lg sm:text-xs  sm:py-2 sm:w-28 sm:hidden md:block"
            onClick={handleBlogsNavigation}
          >
            See Blogs
          </button>
          <button
            className="bg-themeColor md:rounded-lg sm:rounded-md md:px-4 md:py-3 md:text-lg sm:text-xs  sm:py-2 sm:w-28"
            onClick={() => navigate("/about")}
          >
            About Us
          </button>
        </div>
      </div>
    </div>
  );
}
