import React from "react";

export default function Footer() {
  return (
    <div className="bg-themeColor text-white md:px-20 md:py-3 sm:px-5 sm:py-3 w-screen flex flex-col gap-4 mt-auto">
      <div className="flex justify-between">
        <div className="flex flex-col items-start gap-2 ">
          <label htmlFor="email" className="italic px-1 md:text-xl sm:text-md">
            Subscribe to Us!
          </label>
          <input
            type="text"
            className="rounded-full h-10 md:w-80 px-3"
            placeholder="Enter Your Email"
          />
        </div>
        <div className="flex flex-col items-start md:text-base sm:text-xs gap-1">
          <button>About</button>
          <button>Books</button>
          <button>Blogs</button>
          <button>Contact</button>
        </div>
      </div>
      <div className="text-center text-xs italic">copyright info</div>
    </div>
  );
}
