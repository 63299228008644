import React, { useContext, useEffect, useState } from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import logo from "src/assets/logo.png";
import { WindowContext } from "src/contexts/useWindowSize";
import { useNavigate, useLocation } from "react-router-dom";
export default function Header() {
  const [menu, setMenu] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { windowSize } = useContext(WindowContext);
  console.log(windowSize);
  const handleBlogsNavigation = () => {
    if (location.pathname != "/") navigate("/");
    window.scrollTo({
      top: 630,
      behavior: "smooth",
    });
  };
  return (
    <div className="bg-themeColor text-white px-7 py-3 flex justify-between gap-10 items-center w-screen">
      <button
        className="bg-white text-black rounded-full md:w-14 md:h-14 sm:w-10 sm:h-10 flex items-center justify-center"
        onClick={() => navigate("/")}
      >
        <img src={logo} alt="" className="rounded-full" />
      </button>
      {/* <div className="lg:text-4xl md:text-3xl sm:text-base">Culinary Guide</div> */}
      {windowSize == 1 ? (
        <button onClick={() => setMenu(!menu)}>
          <RxHamburgerMenu className="text-2xl" />
        </button>
      ) : (
        <div className="flex gap-5 font-thin text-lg ">
          <button onClick={handleBlogsNavigation}>Blogs</button>
          <button onClick={() => navigate("/books")}>Books</button>
          <button onClick={() => navigate("/about")}>About</button>
          <button onClick={() => navigate("/contact")}>Contact</button>
        </div>
      )}
      {menu && (
        <div className="rounded absolute right-0 top-14  flex flex-col [&>*]:border-b [&>*]:border-b-white [&>*]:px-4 [&>*]:py-3 py-2 bg-themeColor">
          <button onClick={handleBlogsNavigation}>Blogs</button>
          <button onClick={() => navigate("/books")}>Books</button>
          <button onClick={() => navigate("/about")}>About</button>
          <button onClick={() => navigate("/contact")}>Contact</button>
        </div>
      )}
    </div>
  );
}
