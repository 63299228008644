import React, { useState, createContext, useEffect } from "react";

const WindowContext = createContext({
  windowSize: 3,
});

const WindowProvider = ({ children }) => {
  const windowSizeCalc = () =>
    window.innerWidth < 640 ? 1 : window.innerWidth < 1024 ? 2 : 3;
  const [windowSize, setWindowSize] = useState(windowSizeCalc());

  useEffect(() => {
    setWindowSize(windowSizeCalc());
    console.log(windowSize);
  }, [window.innerWidth]);
  console.log("this is the window size", windowSize);
  return (
    <WindowContext.Provider value={{ windowSize }}>
      {children}
    </WindowContext.Provider>
  );
};

export { WindowContext, WindowProvider };
