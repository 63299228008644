import React from "react";

export default function Main() {
  const items = [
    {
      id: 1,
      text: "Pasta",
    },
    {
      id: 1,
      text: "Maggi",
    },
    {
      id: 1,
      text: "Laddoo",
    },
    {
      id: 1,
      text: "Samosa Chat",
    },
    {
      id: 1,
      text: "Sandwich",
    },
    {
      id: 1,
      text: "Macaroon",
    },
    {
      id: 1,
      text: "Macaroon",
    },
    {
      id: 1,
      text: "Macaroon",
    },
  ];

  const menuItems = [
    {
      id: 1,
      item: "About",
    },
    {
      id: 2,
      item: "Articles",
    },
    {
      id: 1,
      item: "Reviews",
    },
    {
      id: 1,
      item: "Posts",
    },
    {
      id: 1,
      item: "Author",
    },
    {
      id: 1,
      item: "Subscribe for Notifications",
    },
    {
      id: 1,
      item: "Contact Us",
    },
  ];
  return (
    <div className="flex flex-col items-center w-screen">
      <div className="flex flex-col items-center py-3 px-5 gap-5">
        {/* <input
          type="text"
          placeholder="Search"
          className="rounded-full px-3 py-2 bg-themeColor text-white w-64"
        /> */}
        <div className="md:text-6xl sm:text-3xl font-semibold font-mono">
          Blogs
        </div>
        <div className="grid md:grid-cols-3 md:grid-rows-2 sm:grid-cols-1 gap-3">
          {items.map((item) => (
            <div className="rounded-xl bg-themeColor text-white flex flex-col">
              <img
                src="https://imgs.search.brave.com/b0A_0k2xznt65KE5fxxOXZd2ZdkSSSDRGoggq9wLAQk/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9tZWRp/YS5nZXR0eWltYWdl/cy5jb20vaWQvNDg4/OTYwOTA4L3Bob3Rv/L2hvbWVtYWRlLXBh/c3RhLmpwZz9zPTYx/Mng2MTImdz0wJms9/MjAmYz1QSFl5c2pE/U3V3aDlEMEtZTlRx/YW9BZEJEOWhvN2tz/Qzcyb3dlSWpUMGRF/PQ"
                alt=""
                className="rounded-t-xl md:h-48 sm:h-40"
              />
              <div className=" px-4 py-3">{item.text}</div>
            </div>
          ))}
        </div>
      </div>
      {/* <div className="flex flex-col ml-auto border-l border-l-black">
        {menuItems.map((item) => (
          <button className="px-5 py-2 text-left">{item.item}</button>
        ))}
      </div> */}
    </div>
  );
}
