import React from "react";
import Header from "src/components/Landing/Header";
import Footer from "src/components/Footer/Footer";
export default function Contact() {
  const inputFields = [
    {
      id: 1,
      name: "name",
      type: "text",
      placeholder: "Name",
    },
    {
      id: 2,
      name: "email",
      type: "email",
      placeholder: "Email",
    },
    {
      id: 3,
      name: "message",
      type: "text",
      placeholder: "Message",
    },
  ];
  return (
    <div>
      <Header />
      <div className="h-[90vh] flex items-center justify-center ">
        <form
          action=""
          className="flex flex-col w-96 gap-4 bg-[#ffe5b4] px-10 py-10 rounded-xl border border-themeColor"
        >
          {inputFields.map((field) => (
            <input
              type={field.type}
              placeholder={field.placeholder}
              name={field.name}
              key={field.id}
              className="border border-themeColor rounded-full px-2 py-1"
            />
          ))}
          <button
            type="submit"
            className="px-3 py-2 bg-themeColor text-white rounded-full w-28 mx-auto mt-5"
          >
            Send
          </button>
        </form>
      </div>
      <Footer />
    </div>
  );
}
