import { Routes, Route } from "react-router-dom";
import Landing from "./pages/Landing.jsx";
import About from "./pages/About.jsx";
import { Suspense } from "react";
import Books from "./pages/Books.jsx";
import Contact from "./pages/Contact.jsx";
function App() {
  return (
    <Routes>
      <Route index element={<Landing />} />
      <Route
        path="/about"
        element={
          <Suspense fallback={<div className="h-screen">Loading...</div>}>
            {<About />}
          </Suspense>
        }
      />
      <Route
        path="/books"
        element={
          <Suspense fallback={<div className="h-screen">Loading...</div>}>
            {<Books />}
          </Suspense>
        }
      />
      <Route
        path="/contact"
        element={
          <Suspense fallback={<div className="h-screen">Loading...</div>}>
            {<Contact />}
          </Suspense>
        }
      />
    </Routes>
  );
}

export default App;
