import React from "react";
import Header from "../components/Landing/Header";
import Main from "../components/Landing/Main";
import BlogPage from "src/components/Blogs/BlogPage";
import Footer from "src/components/Footer/Footer";
export default function Landing() {
  return (
    <div className="overflow-hidden">
      <Header />
      <Main />
      <BlogPage />
      <Footer />
    </div>
  );
}
