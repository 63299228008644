import React from "react";
import Header from "../components/Landing/Header";
import Footer from "src/components/Footer/Footer";
import dummyChef from "src/assets/chefPhoto.png";
export default function About() {
  return (
    <div className="overflow-hidden">
      <Header />
      <div className="flex lg:flex-row sm:flex-col lg:px-10 lg:py-5 sm:py-3 sm:px-4 items-center gap-10">
        <img
          src={dummyChef}
          alt=""
          className="rounded-full w-80 border border-black border-2"
        />
        <div className="bg-[#ffe5b4] lg:rounded-2xl sm:rounded-xl lg:py-10 sm:py-5 lg:px-20 sm:px-6 border border-black font-semibold font-mono">
          Committed and willing Hospitality Industry proffessional. I am an
          enthusiastic, self-motivated, reliable, responsible and hard working
          person. I am able to work well both in a team environment as well as
          using own initiative.
        </div>
      </div>
      <Footer />
    </div>
  );
}
