import React, { useState } from "react";
import Header from "../components/Landing/Header";
import Footer from "src/components/Footer/Footer";
export default function Books() {
  const bookImage =
    "https://imgs.search.brave.com/-pkDCq4fKD8y94SRM1uMmXVT9Yr3OI4s2E4UNfrFC9M/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9jZG4u/YXBhcnRtZW50dGhl/cmFweS5pbmZvL2lt/YWdlL3VwbG9hZC9m/X2F1dG8scV9hdXRv/OmVjbyx3XzczMC9r/L2FyY2hpdmUvYzU1/YjBkODNlMWU1Zjhl/Mjk3NWMzMGU5MmIy/MjI0OTFjYzc2YWJj/Yw";
  const [books, setBooks] = useState([
    {
      id: 1,
      book_name: "The Flavor Bible",
      book_image: bookImage,
    },
    {
      id: 1,
      book_name: "The Flavor Bible",
      book_image: bookImage,
    },
    {
      id: 1,
      book_name: "The Flavor Bible",
      book_image: bookImage,
    },
    {
      id: 1,
      book_name: "The Flavor Bible",
      book_image: bookImage,
    },
    {
      id: 1,
      book_name: "The Flavor Bible",
      book_image: bookImage,
    },
    {
      id: 1,
      book_name: "The Flavor Bible",
      book_image: bookImage,
    },
    {
      id: 1,
      book_name: "The Flavor Bible",
      book_image: bookImage,
    },
  ]);
  return (
    <div className="overflow-hidden">
      <Header />
      <div className="text-center text-3xl my-10 font-semibold">Pick your favourite books and start cooking now!</div>
      <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-8 my-10 md:mx-10 sm:mx-5">
        {books.map((book) => (
          <div
            className="flex flex-col bg-themeColor text-white items-center rounded-xl"
            key={book.id}
          >
            <div className="md:w-64 sm:w-56">
              <img src={book.book_image} alt="" />
            </div>
            <div className="my-10 text-3xl font-semibold">{book.book_name}</div>
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
}
